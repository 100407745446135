import React from "react";
import axios from "axios";
import { useState } from "react";

export default function Upload () {

    const [uploadedFile, setUploadedFile] = useState ('');
    const [fileTitle, setFileTitle] = useState ('');
  
    function handleFormSubmittion (e) {
      e.preventDefault ();
  
      let form = document.getElementById ('form');
      let formData = new FormData (form);
  
      // do something
      axios.post ('http://localhost:8000/candidate/uploadxls', formData);
    }
  
    function handleFileTitle (e) {
      setFileTitle (e.target.value);
    }
  
    function handleUploadedFile (e) {
      setUploadedFile (e.target.value);
    }
    return(
        <>
            <form
                encType="multipart/form-data"
                onSubmit={handleFormSubmittion}
                id="form"
            >
                <input
                type="file"
                name="uploadedFile"
                value={uploadedFile}
                onChange={handleUploadedFile}
                required
                />
                <br />

                <button type="submit">Submit Form</button>
            </form>
        </>
    )
}