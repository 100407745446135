import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

async function validateLogin(data) {
    let response = await axios.post('http://127.0.0.1:8000/login/login', {
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    })

    return(response)
}

export default function Login() {

    const navigate = useNavigate()

    const [userName, setUserName] = useState()
    const [password, setPassword] = useState()

    const LoginValidate = async e => {
        e.preventDefault()
        let response = await validateLogin({userName, password})
        if(response.data[0].password == password){
            navigate('/dashboard')
            sessionStorage.setItem("username", userName);
        }

        
    }

    return(
    <>    
        <div className="container d-flex justify-content-center" style={{minHeight: "70vh!important"}}>
            <div className="my-auto">

                <div className="border-0 p-0 p-md-4 login-card">

                    <form>
                        <div className="row g-1">
                            <div className="col-3 col-md-4">
                                <div className="text-end">
                                    <a href="#"><img className="login-logo" src="img/indian2.png" /></a>
                                </div>
                            </div>
                            <div className="col-9 col-md-8">
                                <div className="mb-3">
                                <input type="email" onChange={e => setUserName(e.target.value)} className="form-control bg-transparent border border-dark" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="User Name" />
                                </div>
                                <div className="mb-3">
                                <input type="password" onChange={e => setPassword(e.target.value)} className="form-control bg-transparent border border-dark" id="exampleInputPassword1"  placeholder="Password" />
                                </div>
                                <div className="mb-3">
                                <a onClick={LoginValidate} className="btn btn-light shadow fw-bold text-dark w-100" type="button" style={{letterSpacing: "0.05rem"}}>LOGIN</a>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    </>

    )
}