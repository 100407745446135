import { useNavigate } from "react-router-dom"

export default function CandidateCard(props) {

    const navigate = useNavigate()

    const toDashboard = e => {
        e.preventDefault();
        sessionStorage.setItem("post", props.post);
        navigate('/daywise')
    }

    return(
                    <div className="col-12 col-md-3 trade-cards">
                        <a onClick={toDashboard} className="text-decoration-none">
                            <div className="row g-0">
                                <div className="col-12 col-lg-5 px-3 py-md-2 py-lg-4 text-" style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}>

                                    <p className="fw-bold m-0 text-secondary" style={{letterSpacing:0.05+"rem"}}>TOTAL</p>
                                    <p className="fw-bold fs-5 text-light m-0" style={{letterSpacing:0.1+"rem"}}>{props.post}</p>

                                </div>
                                <div className="col-12 col-lg-7 px-2 pt-3 text-dark text-end" style={{backgroundColor: "rgba(98, 98, 98, 0.2)"}}>

                                    <p className="fw-bold display-4 m-0" style={{letterSpacing:0.1+"rem"}}>{props.count}</p>

                                </div>
                            </div>
                        </a>
                    </div>
    )
}