import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TableRows from "./subcomponents/tablerows1";
// import DatePicker from 'react-datepicker'

export default function Daywise () {

    const navigate = useNavigate()

    const [totalCandidates, gettotalCandidates] = useState([]);

    const toDashboard = e => {
        navigate('/dashboard')
    }

    const ExamData = async () => await axios.get("http://127.0.0.1:8000/candidate/totalCandidates", { params: {user: sessionStorage.getItem("username") , post : sessionStorage.getItem("post")}}).then((response) => {
            const rowGet = response.data;
            gettotalCandidates(rowGet)
    })

    const toLogin = e => {
        navigate('/')
    }

    const DownloadXls = async e => {
        
    }

    const DownloadPdf = async e => {
        
    }

    const UploadXls = async e => {
        
    }

    const DownloadSummary = async e => {
        
    }

    useEffect(() => {
        ExamData();
    }, [])


    return(
        <>
            <div class="container" style={{minHeight: 70+"vh!important"}}>

                <div class="d-flex align-items-center justify-content-between">
                    <div class="py-3">
                        <p class="m-0 fs-18">Welcome, User Name</p>
                        <span class="fs-14 rounded bg-white px-2">Center Name <i class="bi bi-caret-right-fill"></i> ITI, Birubari, Guwahati</span>
                    </div>
                    <a onClick={toLogin} class="btn btn-secondary rounded-0 px-0 px-1 fs-18 lh-80">
                        <i class="bi bi-power"></i><br />
                        <span class="fs-10">LOGOUT</span>
                    </a>
                </div>

                <div class="mb-3">
                    <p class="m-0 display-6">Day Wise List</p><hr />
                </div>

                <div class="mb-3 theme-1 p-2">
                    <div class="mt-3 px-4 py-2">
                                    
                        <table id="example" class="display text-dark border-white  nowrap" style={{width:100+"%"}}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Post Name</th>
                                    <th>Total Scheduled</th>
                                    <th>Total Appeared</th>
                                    <th class="text-center">Download</th>
                                    <th class="text-center">Upload</th>
                                    <th class="text-center">Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalCandidates.map((option, index) => (
                                <TableRows key={index} date={option.exam_date} post={option.post_name} count={option.NumberOfCandidates} />
                                ))}
                                </tbody>      
                        </table>

                    </div>
                </div>

                <div class="">
                <a onClick={toDashboard} class="btn btn-secondary rounded-0"><i class="bi bi-chevron-left"></i> BACK</a>
                </div>

                </div>



                <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-body">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="m-0">Upload File</p>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <hr/>
                    <p class="">Upload only <i class="bi bi-file-earmark-spreadsheet"></i>.Excel file(s).</p>
                    <input type="file" class="form-control"/>
                    <div class="text-end py-2"><button class="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Submit</button></div>
                </div>
            </div>
            </div>
            </div>


        </>
    )
}