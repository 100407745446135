import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CandidateCard from "./subcomponents/candidatecard"

export default function Dashboard () {

    const navigate = useNavigate()

    const [usernameSS, getUsernameSS] = useState() 
    const [vivaList, getVivaList] = useState([])
    const toDashboard = e => {
        e.preventDefault()
        navigate('/daywise')
    }

    const getbypost = async () => await axios.get("http://127.0.0.1:8000/candidate/getbypost", {params: {user: sessionStorage.getItem("username")}}).then(response => {
            const rowGet = response.data;
            getVivaList(rowGet)
            console.log(rowGet)
            console.log(username)

        })


    const toLogin = e => {
        navigate('/')
    }

    const username = 

    useEffect(() => {
        getbypost();
    }, []);




    return (
        <div className="container" style={{minHeight: 70+"vh!important"}}>

            <div className="d-flex align-items-center justify-content-between">
                <div className="py-3">
                    <p className="m-0 fs-18">Welcome, {username}</p>
                    <span className="fs-14 rounded bg-white px-2">CENTER ID <i className="bi bi-caret-right-fill"></i> {sessionStorage.getItem("username")}</span>
                </div>
                <a onClick={toLogin} className="btn btn-secondary rounded-0 px-0 px-1 fs-18 lh-80">
                    <i className="bi bi-power"></i><br />
                    <span className="fs-10">LOGOUT</span>
                </a>
            </div>

            <div className="mb-3">
                <p className="m-0 display-6">Trades</p><hr />
            </div>

            <div className="mb-3">
                
                
                <div className="row g-3">

                {vivaList.map((option, index) =>(
                    <CandidateCard key={index} post={option.post_name} count={option.NumberOfCandidates} />
                ))}

                </div>
            </div>

        </div>



    )
}