import React from "react";

export default function Footer() {

    return(
        <>
            <div className="container-fluid text-center theme-2 p-4">
                <p className="m-0">&copy; All Right Reserved 2023 | STATE LEVEL POLICE RECRUITMENT BOARD, ASSAM</p>
            </div>
            
            <script src="https://code.jquery.com/jquery-3.6.4.js" />
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js" />



            
            <script src="https://cdn.datatables.net/1.12.1/js/jquery.dataTables.min.js" />
            <script src="https://cdn.datatables.net/1.12.1/js/dataTables.bootstrap.min.js" />
            <script src="https://cdn.datatables.net/fixedheader/3.2.4/js/dataTables.fixedHeader.min.js" />
            <script src="https://cdn.datatables.net/responsive/2.3.0/js/dataTables.responsive.min.js" />
            <script src="https://cdn.datatables.net/responsive/2.3.0/js/responsive.bootstrap.min.js" />
            
            <script src="js/datatable-script.js" />
            <script src="js/custom.js" />
        </>

    )
}