import fileDownload from "js-file-download";
import DownloadCSV from "./downloadcsv";
import Upload from "./upload";
import axios from "axios";
import { useState } from "react";
import csvDownload from 'json-to-csv-export'

export default function Tablerows(props) {

    const [serverData, getServerData] = useState([])

    const getCsv =  async e =>{
        e.preventDefault();
        await axios.get("http://127.0.0.1:8000/candidate/csv", {params: {date: props.date, user: sessionStorage.getItem("username"), post: sessionStorage.getItem("post")}}).then(response => {
            console.log(response.data)
            const rowGet = response.data;
            getServerData(rowGet)
        })
    }

    const dataToConvert = {
        data: serverData,
        filename: 'blank_pdf',
        delimiter: ',',
        headers: [ 'id', 'roll_no', 'name', 'gender', 'caste_name', 'dob', 'grooming_marks', 'attitude_marks', 'confidence_marks', 'oral_test', 'experience_marks', 'total_marks' ]
      }

    return(
        <tr>    
            <td>{props.date}</td>
            <td>{props.post}</td>
            <td>{props.count}</td>
            <td>Upcoming</td>
            <td class="text-center p-1">
            <button onClick={getCsv} class="btn rounded- p-0 px-4 btn-outline-success bi bi-file-earmark-spreadsheet fs-4">Generate</button>
            <button onClick={() => csvDownload(dataToConvert)} class="btn rounded- p-0 px-4 btn-outline-success bi bi-file-earmark-spreadsheet fs-4">Download</button>
        </td>
            <td class="text-center p-1"><Upload date={props.date}/></td>
            <td class="text-center p-1"><button class="btn rounded-0 btn-success"><i class="bi bi-download"></i> Download</button></td>
        </tr>
    )
}