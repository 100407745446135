import logo from './logo.svg';
import './App.css';
import Header from './components/header'
import './css/custom.css'
import './css/dashboard-page.css'
import './css/datatable-custom.css'
import './css/header.css'
import './css/login-page.css'
import Footer from './components/footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/dashboard';
import Daywise from './components/daywise'
import Index from './components/index';

function App() {
  return (
    <div className="App">
      <Header />
        <div class="bg-grad" style={{minHeight: "80.5vh"}}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/daywise' element={<Daywise />} />
              {/* <Route path='/' element={<Index />} /> */}
            </Routes>
          </BrowserRouter>
        </div>
      <Footer />
    </div>
  );
}

export default App;
