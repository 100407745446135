import React from "react";
import '../css/custom.css'
import '../css/dashboard-page.css'
import '../css/datatable-custom.css'
import '../css/header.css'
import '../css/login-page.css'

export default function Header() {

    return (
        <>
            <nav className="bg-">
                <div className="container py-3 d-flex align-items-center">
                    <a href="#"><img className="pol_logo" src="../img/police_logo.png" /></a>
                    <div className="px-2 px-md-4 me-auto">
                        <a href="#" className="text-decoration-none text-dark d-none d-md-block">
                            <p className="m-0 fs-18 fw-bold">GOVERNMENT OF ASSAM</p>
                            <p className="m-0 fs-20 fw-bold">STATE LEVEL POLICE RECRUITMENT BOARD, ASSAM</p>
                            <p className="m-0 fs-16">REHABARI:::GUWAHATI</p>
                        </a>
                        <a href="#" className="text-decoration-none text-dark d-block d-md-none">
                            <p className="m-0 fs-12 fw-bold">GOVERNMENT OF ASSAM</p>
                            <p className="m-0 fs-14 fw-bold">STATE LEVEL POLICE RECRUITMENT BOARD, ASSAM</p>
                            <p className="m-0 fs-10">REHABARI:::GUWAHATI</p>
                        </a>
                    </div>
                    <div className="d-flex">
                        <img className="smj_logo" src={require("../img/Seal_of_Assam.png")} />
                    </div>
                </div>
            </nav>
        </>
    )
    
}